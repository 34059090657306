import { createApi } from "@reduxjs/toolkit/query/react";

import { axiosBaseQuery } from "./AxiosBaseQuery";

export const ServerManagementApi = createApi({
    reducerPath: "serverManagementApi",
    baseQuery: axiosBaseQuery(),
    tagTypes: ["serverManagementTag"],

    endpoints: (builder) => ({
        getServerManagementList: builder.query({
            query: (pageData) => ({
                url: `/admin/user-server/list`,
                method: "GET",
                params: {
                    per_page: pageData.per_page ?? Number(process.env.REACT_APP_PER_PAGE),
                    page: pageData.page ?? 1,
                    sort_order: pageData.order ?? process.env.REACT_APP_ORDER,
                    sort_by: pageData.order_by ?? process.env.REACT_APP_ORDER_BY,
                    search: pageData.searchText,
                },
            }),
            providesTags: ["serverManagementTag"],
        }),

        getUsersList: builder.query({
            query: (page) => ({
                url: `/admin/user-server/get-users`,
                method: "GET",
                params: {
                    page: page,
                }
            }),
            providesTags: ["serverManagementTag"],
        }),

        getServersList: builder.query({
            query: (page) => ({
                url: `/admin/user-server/get-servers`,
                method: "GET",
                params: {
                    page: page,
                }
            }),
            providesTags: ["serverManagementTag"],
        }),

        assignServer: builder.mutation({
            query: (data) => ({
                url: `/admin/user-server/assign`,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["serverManagementTag"],
        }),

        deleteServer: builder.mutation({
            query: (id) => ({
                url: `/admin/user-server/unassign/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["serverManagementTag"],
        }),
    })
});

export const {
    useGetServerManagementListQuery,
    useGetUsersListQuery,
    useGetServersListQuery,
    useAssignServerMutation,
    useDeleteServerMutation,
} = ServerManagementApi;
