import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userType: '',
};

export const roleSlice = createSlice({
  name: 'userRole',
  initialState,
  reducers: {
    userRoleAction: (state, action) => ({ ...state, userType: action.payload }),
  },
});

export const { userRoleAction } = roleSlice.actions;

export default roleSlice.reducer;
