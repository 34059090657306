import React from "react";
import { Alert, Stack } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import { GetAlert, removeAlert } from "../../redux/slice/AlertBoxSlice";

const AlertBox = (_props) => {
    const alertValue = useSelector(GetAlert);
    const dispatch = useDispatch();
    const handleClose = (_event, index) => {
        dispatch(removeAlert(index))
    }

    return (
        <Stack className="alert-head" sx={{ width: '100%' }} spacing={2}>
            {alertValue.map((item, index) =>
                <Alert key={index} className="alert-msg" severity={item.type} onClose={(e) => handleClose(e, index)}>{item.message}</Alert>
            )}
        </Stack>
    )
}
export default AlertBox;