import React, { useEffect, useState } from 'react'
import ComplexStatisticsCard from "../../examples/Cards/StatisticsCards/ComplexStatisticsCard";
import { Grid } from '@mui/material';
import MDBox from '../../components/MDBox';
import DnsIcon from '@mui/icons-material/Dns';
import CloudIcon from '@mui/icons-material/Cloud';
import GroupIcon from '@mui/icons-material/Group';
import { useGetDashboardDetailQuery } from '../../redux/apis/DashboardApi';

const Dashboard = () => {

    const [count, setCount] = useState(null);
    const { data } = useGetDashboardDetailQuery();

    useEffect(() => {
        if (data?.success) {
            setCount(data?.data ?? null);
        }
    }, [data]);

    return (
        <Grid container spacing={3} mt={1}>
            <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                        color="dark"
                        icon={<DnsIcon />}
                        title="Servers"
                        count={count?.server_count ?? 0}
                        percentage={{
                            color: "success",
                            amount: "+55%",
                            label: "than lask week",
                        }}
                    />
                </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                        color="dark"
                        icon={<CloudIcon />}
                        title="Total Cloudflare Domains"
                        count={count?.cloudflare_count ?? 0}
                        percentage={{
                            color: "success",
                            amount: "+55%",
                            label: "than lask week",
                        }}
                    />
                </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                        color="dark"
                        icon={<CloudIcon />}
                        title="Total Sucuri Domains"
                        count={count?.sucuri_count ?? 0}
                        percentage={{
                            color: "success",
                            amount: "+55%",
                            label: "than lask week",
                        }}
                    />
                </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                    <ComplexStatisticsCard
                        color="dark"
                        icon={<GroupIcon />}
                        title="Total Users"
                        count={count?.user_count ?? 0}
                        percentage={{
                            color: "success",
                            amount: "+55%",
                            label: "than lask week",
                        }}
                    />
                </MDBox>
            </Grid>
        </Grid>
    )
}

export default Dashboard;