import React, { useState, useMemo, useEffect, useCallback } from 'react'
import { Backdrop, Box, Container, debounce, Fade, Modal, Paper, StyledEngineProvider, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, Toolbar, Typography } from '@mui/material'
import { SearchElement } from '../../components/Search/Search'
import { TableHeadElement } from '../../components/Table/TableHeadElement'
import TableBodyElement from '../../components/Table/TableBodyElement'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDeleteUserMutation, useGetUserListQuery } from '../../redux/apis/UserManagementApi'
import ConfirmBox from '../../components/ConfirmBox/ConfirmBox'
import AddEditUser from './AddEditUser'

const tableColumns = [
    {
        id: "id",
        sort: false,
        label: "S.No.",
    },
    {
        id: "name",
        sort: true,
        label: "Name",
    },
    {
        id: "email",
        sort: true,
        label: "Email Address",
    },
    {
        id: "role_id",
        sort: false,
        label: "Role",
    },
    {
        id: "action",
        sort: false,
        label: "Action",
        style: { width: "15%" },
    },
];
let initialOrder = process.env.REACT_APP_ORDER;
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 10,
    borderRadius: 2,
};
const UserDashboard = () => {
    const initialState = {
        page: 1,
        per_page: Number(process.env.REACT_APP_PER_PAGE) ?? 20,
        searchText: "",
        order: process.env.REACT_APP_ORDER,
        order_by: process.env.REACT_APP_ORDER_BY,
    };

    let navigate = useNavigate();
    let query = useLocation();

    const [isClose, setIsClose] = useState(false);
    const [Id, setId] = useState("");
    const [open, setOpen] = useState(false);
    const [orderBy, setOrderBy] = useState(process.env.REACT_APP_ORDER_BY);
    const [order, setOrder] = useState(initialOrder);
    const [search, setSearch] = useState("");
    const [rowsPerPage, setRowsPerPage] = useState(initialState.per_page);
    const [userList, setUserList] = useState(initialState);
    const [deleteUser] = useDeleteUserMutation();
    const [userListData, setUserListData] = useState({
        data: [],
        last_page: 1,
        page: 0,
        total: 0,
    });

    const UserListApiCall = useGetUserListQuery({
        per_page: userList.per_page ?? rowsPerPage,
        page: query.state ?? userList.page,
        order: userList.order ?? order,
        order_by: userList.order_by ?? orderBy,
        searchText: userList.searchText ?? search,
    });

    const convertRole = (roleId) => {
        let roleName = '';
        switch (roleId) {
            case 1:
                roleName = "Super Admin";
                break;
            case 2:
                roleName = "Admin";
                break;
            case 3:
                roleName = "User";
                break;
            case 4:
                roleName = "Dev User";
                break;
            default:
                roleName = "Not Define";
                break;
        };
        return roleName;
    }

    useEffect(() => {
        if (UserListApiCall?.data?.success) {
            let data = UserListApiCall?.data?.data?.data?.length > 0 ? UserListApiCall?.data?.data?.data?.map((item) => ({ ...item, role_id: convertRole(item?.role_id) })) : []
            setUserListData({
                data: data,
                page: UserListApiCall?.data?.data?.current_page - 1,
                last_page: UserListApiCall?.data?.data?.last_name,
                total: UserListApiCall?.data?.data?.total,
            });
        }
    }, [UserListApiCall]);

    const memoizedSetOpen = useCallback((value) => setOpen(value), []);
    const memoizedSetId = useCallback((value) => setId(value), []);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        const newOrder = isAsc ? "desc" : "asc";
        setOrder(newOrder);
        setOrderBy(property);
        setUserList({
            ...userList,
            order: newOrder,
            order_by: property,
        });
    };

    const createSortHandler = (event, property) => {
        if (property !== ("action")) {
            handleRequestSort(event, property);
        }
    };

    const handleChangePage = (event, newPage) => {
        setUserList({ ...userList, page: newPage + 1 });

        if (query.state !== null) {
            navigate(query.pathname, {});
        }
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setUserList({
            ...userList,
            per_page: parseInt(event.target.value),
            page: 1,
        });
    };

    const searchHandler = useMemo(() => {
        return debounce((event) => {
            setSearch(event.target.value);
            setUserList({
                page: 1,
                per_page: userList.per_page,
                order: initialOrder,
                searchText: event.target.value,
            });
        }, 800);
    }, [userList]);

    const confirmBoxClose = (res) => {
        setIsClose(res);
        if (res) {
            setIsClose(false);
            deleteUser(Id);
        } else {
            setIsClose(res);
        }
    };

    return (
        <Paper className="table-outers">
            <Toolbar>
                <Typography
                    sx={{ flex: '1 1 40%' }}
                    variant="h5"
                    id="tableTitle"
                    component="div"
                    className="inner-headings"
                >
                    User Dashboard
                </Typography>

                <div className="flex-boxinner">
                    <Typography
                        sx={{ flex: '1 1 auto' }}
                    >
                        <SearchElement
                            searchFn={searchHandler}
                            searchTag={`Search User...`}
                        />

                    </Typography>
                </div>
            </Toolbar>
            <StyledEngineProvider>
                <TableContainer component={Paper} className="table-outers" style={{ maxHeight: 700, overflowY: 'auto' }}>
                    <Table
                        sx={{ minWidth: 650, maxHeight: 650, borderCollapse: "collapse" }}
                        size="small" aria-label="a dense table"
                        stickyHeader
                    >
                        <TableHead className='table-head'>
                            <TableRow>
                                <TableHeadElement
                                    setHead={tableColumns ? tableColumns : []}
                                    order={order}
                                    orderBy={orderBy}
                                    sortFn={(event, id) =>
                                        createSortHandler(event, id)
                                    }
                                />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableBodyElement
                                selectedColumns={[
                                    "name",
                                    "email",
                                    'role_id'
                                ]}
                                setData={userListData.data ? userListData.data : []}
                                editFn={(id) => {
                                    setId(id);
                                    setOpen(true);
                                }}
                                deleteFn={(id) => {
                                    setIsClose(true);
                                    setId(id);
                                }}
                                pageData={{
                                    limit:
                                        rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE),
                                    page: userListData.page,
                                }}
                            />
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    component="div"
                    count={userListData.total ?? 0}
                    page={userListData.page ?? 0}
                    rowsPerPageOptions={[20, 50, 100]}
                    onPageChange={handleChangePage}
                    rowsPerPage={
                        rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE)
                    }
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </StyledEngineProvider>
            {open &&
                <Container maxWidth={false} className='p-0' >
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={open}
                        closeAfterTransition
                        slots={{ backdrop: Backdrop }}
                        slotProps={{
                            backdrop: {
                                timeout: 500,
                            },
                        }}
                    >
                        <Fade in={open}>
                            <Box sx={style}>
                                <AddEditUser id={Id} setOpen={memoizedSetOpen} setId={memoizedSetId} />
                            </Box>
                        </Fade>
                    </Modal>
                </Container>
            }
            <ConfirmBox
                title="User Delete Confirmation"
                message="Are you sure, you want to delete this User?"
                open={isClose}
                onClose={confirmBoxClose}
            />
        </Paper>
    )
}

export default UserDashboard