import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const ConfirmBox = (props) => {
    let { onClose, open, message, showSubmitButton = true } = props;

    const handleClose = (isTrue = false) => {
        onClose(isTrue);
    };

    return (
        <Dialog className="dialogBox-main" onClose={() => handleClose(false)} open={open}>
            <DialogTitle id="alert-dialog-title">
                {props.title}
                <IconButton
                    aria-label="close"
                    onClick={() => handleClose(false)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <DialogContentText id="alert-dialog-description">
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button className="close-btn" variant="outlined" color="error" onClick={() => handleClose(false)}>
                    No
                </Button>
                {showSubmitButton && (
                    <Button className="save-btn" variant="outlined" color="success" onClick={() => handleClose(true)} autoFocus>
                        Yes
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
}
export default ConfirmBox
