import { createApi } from "@reduxjs/toolkit/query/react";

import { axiosBaseQuery } from "./AxiosBaseQuery";

export const AuthApi = createApi({
    reducerPath: "authAPI",

    baseQuery: axiosBaseQuery(),

    tagTypes: ["authTag"],

    endpoints: (builder) => ({
        doLogin: builder.mutation({
            query: (mtuser) => ({
                url: `/login`,
                method: "POST",
                body: mtuser,
            }),

            invalidatesTags: ["authTag"],

            transformResponse: (result, _meta, _arg) => {
                if (result.success) {
                    const { token } = result.data;
                    localStorage.setItem('token', token);
                }
                return result;
            },
        }),
    })
});

export const {
    useDoLoginMutation,
} = AuthApi;
