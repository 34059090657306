import React, { useCallback } from 'react'
import { Button, TableCell, TableRow, Tooltip } from '@mui/material'
import EditIcon from "@mui/icons-material/Edit";
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import {
    DeleteForeverRounded
} from "@mui/icons-material";
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import CreateIcon from '@mui/icons-material/Create';
import SkipNextIcon from '@mui/icons-material/SkipNext';

const TableBodyElement = (props) => {
    const iconStyles = {
        display: "flex",
        justifyContent: "space-between",
        textAlign: "center",
    };

    const iconColorMap = {
        success: { icon: <ThumbUpIcon />, color: "green" },
        fail: { icon: <ThumbDownIcon />, color: "red" },
        progress: { icon: <AutorenewIcon />, color: "black" },
        created: { icon: <CreateIcon />, color: "green" },
        skipped: { icon: <SkipNextIcon />, color: "green" },
    };

    let tableColumnWidth = props.styleFn;

    const tableColumnsData = useCallback((columnsData) => {
        const variableType = ["object"];
        if (variableType.includes(typeof columnsData)) {
            return columnsData ? (columnsData).join(", ") : "";
        }
        return columnsData;
    }, []);

    const dateFormatter = useCallback((unixTimestamp) => {
        const date = new Date(unixTimestamp);
        const year = date.getFullYear() ?? ' ';
        const month = (date.getMonth() + 1).toString().padStart(2, '0') ?? ' ';
        const day = date.getDate().toString().padStart(2, '0') ?? ' ';
        const formattedDateTime = `${day}-${month}-${year}`;
        return formattedDateTime;
    }, []);

    const renderTableCellContent = (columnName, columnData) => {
        switch (columnName) {
            case 'createdAt':
                return dateFormatter(columnData);
            case 'job_name':
                return (
                    <a href={columnData?.job_url} target="_blank" rel="noreferrer">
                        {tableColumnsData(columnData)}
                    </a>
                );
            case 'job_status': {
                const statusIcon = iconColorMap[columnData];
                if (statusIcon) {
                    return (
                        <div style={iconStyles}>
                            <span
                                style={{
                                    color: statusIcon.color,
                                    fontSize: "large",
                                    marginTop: "2px",
                                }}
                            >
                                {statusIcon.icon}
                            </span>
                            {columnData}
                        </div>
                    );
                }
                return tableColumnsData(columnData);
            }
            case 'server_name':
                return columnData?.hostname + " (" + columnData?.ip_address + ") "
            default:
                return tableColumnsData(columnData);
        }
    };

    return (
        <>
            {(props.setData ? props.setData : []).map(
                (column, index) => (
                    <TableRow key={index}
                        style={
                            column.is_debited !== undefined
                                ? { backgroundColor: column.is_debited ? "#bf3030" : "#399861" }
                                : {}
                        }
                    >
                        {props.showSerialNumber !== false &&
                            <TableCell>
                                {props.pageData &&
                                    props.pageData?.limit * props.pageData?.page + index + 1}
                            </TableCell>
                        }
                        {props.selectedColumns.map((columnName, indx) => (
                            <TableCell
                                key={columnName}
                                align="left"
                                style={{ width: tableColumnWidth }}
                            >
                                {renderTableCellContent(columnName, column[columnName])}
                            </TableCell>
                        ))}
                        {!props?.showAction && (
                            <TableCell align="left">
                                {typeof props.deployServiceFn === "function" &&
                                    (column.is_debited === false ||
                                        column.is_debited === undefined) &&
                                    (column?.service_status === 1 ||
                                        column?.service_status === undefined) && (
                                        <Tooltip title="Run Deploy" arrow className="me-1 mt-1">
                                            <Button
                                                onClick={() =>
                                                    typeof props.deployServiceFn === "function" &&
                                                    props.deployServiceFn(column)
                                                }
                                                className="action-btn"
                                            >
                                                <RocketLaunchIcon />
                                            </Button>
                                        </Tooltip>
                                    )
                                }
                                {typeof props.editFn === "function" &&
                                    (column.is_debited === false ||
                                        column.is_debited === undefined) && (
                                        <Tooltip title="Edit" arrow className="me-1 mt-1">
                                            <Button
                                                onClick={() =>
                                                    typeof props.editFn === "function" &&
                                                    props.editFn(column.id)
                                                }
                                                className="action-btn"
                                            >
                                                <EditIcon />
                                            </Button>
                                        </Tooltip>
                                    )
                                }
                                {typeof props.deleteFn === "function" &&
                                    (column.is_debited === false ||
                                        column.is_debited === undefined) && (
                                        <Tooltip title="" arrow className="me-1 mt-1">
                                            <Button
                                                onClick={() =>
                                                    typeof props.deleteFn === "function" &&
                                                    props.deleteFn(column.id)
                                                }
                                                className="action-btn"
                                                style={{ color: "red" }}
                                            >
                                                <DeleteForeverRounded />
                                            </Button>
                                        </Tooltip>
                                    )
                                }
                            </TableCell>
                        )}
                    </TableRow>
                ))}
            {props?.setData?.length === 0 && (
                <TableRow>
                    <TableCell style={{ textAlign: "center", justifyContent: "center" }} colSpan={12}>
                        <h4>No Record found</h4>
                    </TableCell>
                </TableRow>
            )}
        </>
    )
}

export default TableBodyElement