import React, { useEffect, useRef, useState } from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    Paper,
    TextField,
    Typography,
    Checkbox,
    Autocomplete,
    CircularProgress,
} from '@mui/material';
import {
    useAssignServerMutation,
    useGetServersListQuery,
    useGetUsersListQuery,
} from '../../redux/apis/ServerManagementApi';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

// Move initialValue outside of the component
const initialValue = {
    user_ids: [],
    server_id: '',
};

const AssignServer = ({ id, setOpen, setId }) => {
    const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
    const checkedIcon = <CheckBoxIcon fontSize='small' />;

    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);

    const [pageServer, setPageServer] = useState(1);
    const [isLoadMoreServer, setIsLoadMoreServer] = useState(true);

    const [formData, setFormData] = useState(initialValue);
    const [serverOption, setServerOption] = useState([]);
    const serverRef = useRef(serverOption);

    const [userOption, setUserOption] = useState([]);
    const userRef = useRef(userOption);

    const { isLoading, data: usersList } = useGetUsersListQuery(page, {
        skip: !hasMore,
    });
    const {
        isLoading: isLoadingServerList,
        data: serversList,
    } = useGetServersListQuery(pageServer, { skip: !isLoadMoreServer });

    const [assignServer, result] = useAssignServerMutation();

    useEffect(() => {
        userRef.current = userOption;
    }, [userOption]);

    useEffect(() => {
        if (usersList?.success) {
            const { current_page, last_page } = usersList?.data?.users;
            setUserOption([...userRef.current, ...usersList?.data?.users?.data]);
            setHasMore(current_page < last_page);
        }
    }, [usersList]);

    useEffect(() => {
        serverRef.current = serverOption;
    }, [serverOption]);

    useEffect(() => {
        if (serversList?.success) {
            const { current_page, last_page } = serversList?.data?.servers;
            setServerOption([
                ...serverRef.current,
                ...serversList?.data?.servers?.data,
            ]);
            setIsLoadMoreServer(current_page < last_page);
        }
    }, [serversList]);

    useEffect(() => {
        if (result?.isSuccess && result?.data?.success) {
            setFormData(initialValue);
        }
    }, [result]);

    const handleSelectMultipleServer = (event, newValue) => {
        setFormData({
            ...formData,
            server_id: newValue?.id,
        });
    };

    const handleSelectMultipleUsers = (event, newValue) => {
        setFormData({
            ...formData,
            user_ids: newValue,
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const apiData = {
            ...formData,
            user_ids: formData.user_ids?.map((item) => item?.id),
        };
        assignServer(apiData);
    };

    const handleScroll = (event) => {
        const listboxNode = event.currentTarget;
        const { scrollTop, scrollHeight, clientHeight } = listboxNode;
        if (
            scrollHeight - scrollTop <= clientHeight + 1 &&
            !isLoading &&
            hasMore
        ) {
            setPage((prevPage) => prevPage + 1);
        }
    };

    const handleScrollServer = (event) => {
        const listboxNode = event.currentTarget;
        const { scrollTop, scrollHeight, clientHeight } = listboxNode;
        if (
            scrollHeight - scrollTop <= clientHeight + 1 &&
            !isLoadingServerList &&
            isLoadMoreServer
        ) {
            setPageServer((prevPageServer) => prevPageServer + 1);
        }
    };

    return (
        <Paper style={{ borderRadius: '12px' }}>
            <Card className='main-container' elevation={3}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <CardHeader title={id ? 'Edit Assignee' : 'Assign Server'} />
                    {id && (
                        <HighlightOffIcon
                            className='closeicon'
                            onClick={() => {
                                setOpen(false);
                                setId('');
                            }}
                        />
                    )}
                </Box>
                <Divider sx={{ border: '0.1px solid black', margin: '0px 15px' }} />
                <CardContent>
                    <Box
                        component='form'
                        sx={{ width: '70%', padding: 2 }}
                        onSubmit={handleSubmit}
                    >
                        <Grid container spacing={3}>
                            <Grid item xs={3}>
                                <Typography style={{ fontSize: 'medium', margin: '10px 0px' }}>
                                    Servers:
                                </Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <Autocomplete
                                    freeSolo
                                    id='servers-autocomplete'
                                    disableClearable
                                    options={serverOption}
                                    getOptionLabel={(option) => option?.name}
                                    ListboxProps={{
                                        onScroll: handleScrollServer,
                                        style: {
                                            maxHeight: '300px',
                                            overflowY: 'auto',
                                        },
                                    }}
                                    onChange={handleSelectMultipleServer}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label='Servers'
                                            slotProps={{
                                                input: {
                                                    ...params.InputProps,
                                                    type: 'search',
                                                },
                                            }}
                                            required
                                        />
                                    )}
                                    renderOption={(props, option) => (
                                        <li {...props}>{option?.name}</li>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Typography style={{ fontSize: 'medium', margin: '10px 0px' }}>
                                    Users:
                                </Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <Autocomplete
                                    multiple
                                    id='users-autocomplete'
                                    options={userOption}
                                    value={formData?.user_ids}
                                    name='user_ids'
                                    disableCloseOnSelect
                                    loading={isLoading}
                                    ListboxProps={{
                                        onScroll: handleScroll,
                                        style: {
                                            maxHeight: '300px',
                                            overflowY: 'auto',
                                        },
                                    }}
                                    onChange={handleSelectMultipleUsers}
                                    getOptionLabel={(option) => option?.name}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={
                                                    formData?.user_ids?.some(
                                                        (item) => item.id === option?.id
                                                    ) ?? selected
                                                }
                                            />
                                            {option?.name}
                                        </li>
                                    )}
                                    size='medium'
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label='Users'
                                            placeholder={
                                                formData?.user_ids?.length === 0
                                                    ? 'Select Users'
                                                    : ''
                                            }
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <>
                                                        {isLoading ? (
                                                            <CircularProgress
                                                                color='inherit'
                                                                size={20}
                                                            />
                                                        ) : null}
                                                        {params.InputProps.endAdornment}
                                                    </>
                                                ),
                                            }}
                                            required={formData?.user_ids?.length === 0}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} style={{ display: 'flex' }}>
                                <Button
                                    type='submit'
                                    variant='contained'
                                    className='save-btn'
                                    style={{ marginRight: '18px' }}
                                >
                                    Submit
                                </Button>
                                {id && (
                                    <Button
                                        type='button'
                                        variant='contained'
                                        className='close-btn'
                                        onClick={() => {
                                            setOpen(false);
                                            setId('');
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                )}
                            </Grid>
                        </Grid>
                    </Box>
                </CardContent>
            </Card>
        </Paper>
    );
};

export default AssignServer;
