import React, { useEffect } from 'react';
import Pusher from 'pusher-js';

const PusherComponent = ({ setPusherData }) => {
    useEffect(() => {
        // Enable Pusher logging - don't include this in production
        Pusher.logToConsole = false;
        const pusher = new Pusher(`${process.env.REACT_APP_PUSHER_KEY}`, {
            cluster: `${process.env.REACT_APP_PUSHER_CLUSTER}`,
        });
        const channel = pusher.subscribe('microservice-data');
        channel.bind('microservice-data', function (data) {
            setPusherData(data?.data);
        });
        // Cleanup subscription on component unmount
        return () => {
            channel.unbind('microservice-data');
            pusher.unsubscribe('microservice-data');
        };
    }, [setPusherData]);

    return (
        <>
        </>
    );
};

export default PusherComponent;
