import axios from "axios";

// Base Url For Application
export const BASE_URL = `${process.env.REACT_APP_API_URL}/api/v1`;

export const axiosBaseQuery =
    (
        { baseUrl } = { baseUrl: BASE_URL }
    ) =>
        async ({ url, method, params, body, timeout }) => {
            try {
                const result = await axios({
                    url: baseUrl + url,
                    method,
                    data: body,
                    params,
                    timeout,
                    headers: localStorage.token
                        ? {
                            Authorization: `Bearer ${localStorage.getItem('token')}`,
                        }
                        : {},
                });
                return { data: result.data };
            } catch (axiosError) {
                let err = axiosError;

                return {
                    error: {
                        status: err.response?.status,
                        data: err.response?.data || err.message,
                    },
                };
            }
        };
