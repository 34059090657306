import Dashboard from "../views/dashboard/Dashboard";
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import ComputerIcon from '@mui/icons-material/Computer';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import CloudIcon from '@mui/icons-material/Cloud';
import PersonIcon from '@mui/icons-material/Person';
import UserDashboard from "../views/usermanagement/UserDashboard";
import AddEditUser from "../views/usermanagement/AddEditUser";
import MicroServicesDashboard from "../views/microservices/Dashboard";
import ServerDashboard from "../views/servermanagement/ServerDashboard";
import AssignServer from "../views/servermanagement/AssignServer";
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';

const routes = [
    {
        type: "collapse",
        name: "Dashboard",
        key: "dashboard",
        icon: <SpaceDashboardIcon />,
        route: "/dashboard",
        component: <Dashboard />,
    },
    {
        type: "collapse",
        name: "MicroServices",
        key: "microservice",
        icon: <ComputerIcon />,
        children: [
            // {
            //     type: "collapse",
            //     name: "Infinity Pro",
            //     key: "infinitypro",
            //     icon: <ComputerIcon />,
            //     subchildren: [
            //         {
            //             type: "collapse",
            //             name: "Dashboard",
            //             key: "infinitypro/Dashboard",
            //             icon: <ComputerIcon />,
            //             component: <InfinityProDashboard />,
            //             route: "/infinitypro/Dashboard",
            //         },
            //         {
            //             type: "collapse",
            //             name: "Deploy",
            //             key: "infinitypro/Deploy",
            //             icon: <RocketLaunchIcon />,
            //             component: <InfinityProDeploy />,
            //             route: "/infinitypro/Deploy",
            //         },
            //     ]
            // },
            {
                type: "collapse",
                name: "Dashboard",
                key: "microservice/dashboard",
                icon: <SpaceDashboardIcon />,
                component: <MicroServicesDashboard />,
                route: "/microservice/dashboard",
            },
            // {
            //     type: "collapse",
            //     name: "R1Soft MySQL",
            //     key: "r1softmysql",
            //     icon: <ComputerIcon />,
            //     component: <Dashboard />,
            //     route: "/r1softmysql",
            // },
        ],
    },
    {
        type: "collapse",
        name: "Atlas io",
        key: "atlas-io",
        icon: <LibraryBooksIcon />,
        children: [
            {
                type: "collapse",
                name: "Add Servers",
                key: "servers/add",
                icon: <ComputerIcon />,
                route: "/servers/add",
            },
            {
                type: "collapse",
                name: "Servers reports",
                key: "servers",
                icon: <ComputerIcon />,
                route: "/servers",
            },
            {
                type: "collapse",
                name: "Servers Logins report",
                key: "servers/logins",
                icon: <ComputerIcon />,
                route: "/servers/logins",
            },
            {
                type: "collapse",
                name: "Servers Manage",
                key: "servers/manage",
                icon: <ComputerIcon />,
                route: "/servers/manage",
            },
            {
                type: "collapse",
                name: "Atlas Timeline",
                key: "atlas/timeline",
                icon: <ComputerIcon />,
                route: "/atlas/timeline",
            },
        ],
    },
    {
        type: "collapse",
        name: "Cloudflare",
        key: "cloudflare",
        icon: <CloudIcon />,
        route: "/cloudflare",
        component: <Dashboard />,
    },
    {
        type: "collapse",
        name: "Securi",
        key: "securi",
        icon: <CloudIcon />,
        route: "/securi",
        component: <Dashboard />,
    },
    {
        type: "collapse",
        name: "User Management",
        key: "user-management",
        icon: <PersonIcon />,
        children: [
            {
                type: "collapse",
                name: "User Dashboard",
                key: "user-management/dashboard",
                icon: <SpaceDashboardIcon />,
                component: <UserDashboard />,
                route: "/user-management/dashboard",
            },
            {
                type: "collapse",
                name: "Add User",
                key: "user-management/add",
                icon: <ComputerIcon />,
                component: <AddEditUser />,
                route: "/user-management/add",
            }
        ],
    },
    {
        type: "collapse",
        name: "Server Management",
        key: "server-management",
        icon: <AssignmentTurnedInIcon />,
        children: [
            {
                type: "collapse",
                name: "Dashboard",
                key: "server-management/dashboard",
                icon: <SpaceDashboardIcon />,
                component: <ServerDashboard />,
                route: "/server-management/dashboard",
            },
            {
                type: "collapse",
                name: "Assign Server",
                key: "server-management/assign-server",
                icon: <ComputerIcon />,
                component: <AssignServer />,
                route: "/server-management/assign-server",
            }
        ],
    },
];
export default routes;
