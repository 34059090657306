import React, { useEffect, useState } from 'react'
import { Box, Button, Card, CardContent, CardHeader, Divider, Grid, Paper, TextField, Typography, IconButton, InputAdornment, FormControl, InputLabel, Select, MenuItem, Checkbox, Autocomplete } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useAddUserMutation, useEditUserMutation, useGetListPermissionsQuery, useGetUserDetailByIdQuery } from '../../redux/apis/UserManagementApi';
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const AddEditUser = ({ id, setOpen, setId }) => {
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    const initialValue = {
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
        role: "",
        permissions: []
    };

    const [formData, setFormData] = useState(initialValue);
    const [permissionOption, setPermissionOption] = useState([]);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [passwordError, setPasswordError] = useState('');
    const [showError, setShowError] = useState({ password: false, password_confirmation: false });

    const listPermission = useGetListPermissionsQuery(formData?.role, { skip: ["", "SuperAdmin"]?.includes(formData?.role) });
    const { data: UserDetail } = useGetUserDetailByIdQuery(id, {
        skip: !id,
    });

    const [addUser, result] = useAddUserMutation();
    const [editUser, editResult] = useEditUserMutation();

    useEffect(() => {
        if (UserDetail?.success) {
            const convertRole = (roleId) => {
                let roleName = '';
                switch (roleId) {
                    case 1:
                        roleName = "SuperAdmin";
                        break;
                    case 2:
                        roleName = "Admin";
                        break;
                    case 3:
                        roleName = "User";
                        break;
                    case 4:
                        roleName = "DevUser";
                        break;
                    default:
                        roleName = "Not Define";
                        break;
                };
                return roleName;
            }
            const permissions = UserDetail?.data?.permissions?.length > 0 ? UserDetail?.data?.permissions?.map(item => item?.name) : [];
            setFormData({
                name: UserDetail?.data?.name ?? '',
                email: UserDetail?.data?.email ?? '',
                password: "",
                password_confirmation: "",
                role: convertRole(UserDetail?.data?.role_id),
                permissions: permissions ?? [],
            });
        }
    }, [UserDetail])

    useEffect(() => {
        if (listPermission?.data?.success) {
            setPermissionOption(listPermission?.data?.data ?? [])
        }
    }, [listPermission]);

    useEffect(() => {
        if (result?.isSuccess && result?.data?.success) {
            setFormData({
                name: "",
                email: "",
                password: "",
                password_confirmation: "",
                role: "",
                permissions: []
            });
        }
    }, [result]);

    useEffect(() => {
        if (editResult?.isSuccess && editResult?.data?.success) {
            setFormData({
                name: "",
                email: "",
                password: "",
                password_confirmation: "",
                role: "",
                permissions: []
            });
            setOpen(false);
            setId("");
        }
    }, [editResult, setOpen, setId]);

    const handleClickShowPassword = () => setShowPassword((prev) => !prev);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword((prev) => !prev);

    const handleChange = (event) => {
        const { name, value } = event.target;
        if (name === 'role') {
            setFormData({ ...formData, [name]: value, permissions: [] })
        } else {
            setFormData({ ...formData, [name]: value });
        }

        if (name === 'password_confirmation') {
            if (value !== formData.password) {
                setPasswordError('The confirm password confirmation does not match the password provided.');
                setShowError(prev => ({ ...prev, password_confirmation: true }));
            } else {
                setPasswordError('');
                setShowError(prev => ({ ...prev, password_confirmation: false }));
            }
        }
    };

    const handleSelectMultiple = (event, newValue) => {
        if (newValue?.length === 0) {
            setFormData({
                ...formData,
                permissions: newValue,
            });
        } else {
            setFormData({
                ...formData,
                permissions: newValue,
            });
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (formData.password !== formData.password_confirmation) {
            setShowError(prev => ({ ...prev, password_confirmation: true }));
            setPasswordError('The confirm password confirmation does not match the password provided.');
        } else {
            id ? editUser({ ...formData, id: id }) : addUser(formData);
        }
    };

    return (
        <Paper style={{ borderRadius: "12px" }}>
            <Card className="main-container" elevation={3}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <CardHeader title={id ? "Edit User" : "Add User"} />
                    {id && <HighlightOffIcon className="closeicon" onClick={() => { setOpen(false); setId(""); }} />}
                </Box>
                <Divider sx={{ border: "0.1px solid black", margin: "0px 15px" }} />
                <CardContent>
                    <Box component="form" sx={{ width: '70%', padding: 2 }} onSubmit={handleSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={3}>
                                <Typography style={{ fontSize: "medium", margin: "10px 0px" }}>Name:</Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <TextField
                                    fullWidth
                                    id="name"
                                    required
                                    label="Enter name"
                                    name="name"
                                    type="name"
                                    size="medium"
                                    autoComplete="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Typography style={{ fontSize: "medium", margin: "10px 0px" }}>Email Id:</Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <TextField
                                    fullWidth
                                    id="email"
                                    required
                                    label="Email Id"
                                    name="email"
                                    type="email"
                                    size="medium"
                                    autoComplete="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Typography style={{ fontSize: "medium", margin: "10px 0px" }}>Password:</Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <TextField
                                    fullWidth
                                    required={id ? false : true}
                                    id="password"
                                    label="Password"
                                    name="password"
                                    type={showPassword ? 'text' : 'password'}
                                    size="medium"
                                    autoComplete="password"
                                    value={formData.password}
                                    onChange={handleChange}
                                    variant="outlined"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={handleClickShowPassword}
                                                    edge="end"
                                                    aria-label="toggle password visibility"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    error={showError.password}
                                    helperText={showError.password && passwordError}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Typography style={{ fontSize: "medium", margin: "10px 0px" }}>Confirm Password:</Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <TextField
                                    fullWidth
                                    required={id ? false : true}
                                    id="password_confirmation"
                                    label="Confirm Password"
                                    name="password_confirmation"
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    size="medium"
                                    autoComplete="password"
                                    value={formData.password_confirmation}
                                    onChange={handleChange}
                                    variant="outlined"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={handleClickShowConfirmPassword}
                                                    edge="end"
                                                    aria-label="toggle password visibility"
                                                >
                                                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    error={showError.password_confirmation}
                                    helperText={showError.password_confirmation && passwordError}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Typography style={{ fontSize: "medium", margin: "10px 0px" }}>Roles:</Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Select Roles *</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            variant="outlined"
                                            sx={{
                                                paddingTop: '12px',
                                                paddingBottom: '12px',
                                            }}
                                            label="Select Roles"
                                            name="role"
                                            required
                                            value={formData.role}
                                            onChange={handleChange}
                                        >
                                            <MenuItem value={'SuperAdmin'}>SuperAdmin</MenuItem>
                                            <MenuItem value={'Admin'}>Admin</MenuItem>
                                            <MenuItem value={'User'}>User</MenuItem>
                                            <MenuItem value={'DevUser'}>DevUser</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>
                            {
                                !["", "SuperAdmin"]?.includes(formData.role) &&
                                <>
                                    <Grid item xs={3}>
                                        <Typography style={{ fontSize: "medium", margin: "10px 0px" }}>Permissions:</Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Autocomplete
                                            multiple
                                            id="checkboxes-tags-demo"
                                            options={permissionOption}
                                            value={formData?.permissions}
                                            disableCloseOnSelect
                                            onChange={handleSelectMultiple}
                                            getOptionLabel={(option) => option}
                                            renderOption={(props, option, { selected }) => (
                                                <li {...props}>
                                                    <Checkbox
                                                        icon={icon}
                                                        checkedIcon={checkedIcon}
                                                        style={{ marginRight: 8 }}
                                                        checked={
                                                            formData?.permissions?.find(
                                                                (item) =>
                                                                    item === option
                                                            ) ?? selected
                                                        }
                                                    />
                                                    {option}
                                                </li>
                                            )}
                                            size='medium'
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Permissions"
                                                    placeholder={
                                                        formData?.permissions?.length === 0
                                                            ? "Select Permission"
                                                            : ""
                                                    }
                                                />
                                            )}
                                        />
                                    </Grid>
                                </>
                            }
                            <Grid item xs={12} style={{ display: "flex" }}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    className="save-btn"
                                    style={{ marginRight: "18px" }}
                                    onSubmit={handleSubmit}
                                >
                                    Submit
                                </Button>
                                {id &&
                                    <Button
                                        type="button"
                                        variant="contained"
                                        className="close-btn"
                                        onClick={() => { setOpen(false); setId(""); }}
                                    >
                                        Cancel
                                    </Button>
                                }
                            </Grid>
                        </Grid>
                    </Box>
                </CardContent>
            </Card>
        </Paper>
    )
}

export default AddEditUser