

import { useState, useEffect } from "react";

// react-router-dom components
import { NavLink, useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox/index";
import MDTypography from "../../components/MDTypography";

// Material Dashboard 2 React example components
import SidenavCollapse from "./SidenavCollapse";

// Custom styles for the Sidenav
import SidenavRoot from "./SidenavRoot";
import sidenavLogoLabel from "./styles/sidenav";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from "../../context/index";
import CloseIcon from '@mui/icons-material/Close';
function Sidenav({ color, brand, brandName, routes, ...rest }) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } = controller;
  const collapseName = window.location.pathname.replace("/", "");
  const location = useLocation();
  let textColor = "white";
  const [openDropdowns, setOpenDropdowns] = useState({});

  useEffect(() => {
    const splitUrl = window.location.pathname?.split('/');
    const newDropdowns = {};
    if (splitUrl[1]) {
      newDropdowns[splitUrl[1]] = true;
    }
    setOpenDropdowns(newDropdowns);
  }, [])

  const handleDropdownClick = (key, parentKey = null) => {
    const fullKey = parentKey ? `${parentKey}/${key}` : key;

    setOpenDropdowns((prev) => {
      const newDropdowns = { ...prev };

      // Toggle the current dropdown without affecting the parent
      newDropdowns[fullKey] = !(prev[fullKey] ?? false);

      // Keep parent dropdowns open
      if (parentKey) {
        newDropdowns[parentKey] = true;
      }

      return newDropdowns;
    });
  };

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
      setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location, transparentSidenav, whiteSidenav]);

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(({ type, name, icon, title, noCollapse, key, href, route, children }) => {
    let returnValue;

    const renderSubChildren = (subchildren, parentKey) => {
      return (
        <div style={{ paddingLeft: '20px' }}>
          {subchildren.map((subItem) => (
            <div key={subItem.key}>
              <NavLink to={subItem.route} onClick={() => handleDropdownClick(subItem.key, parentKey)}>
                <SidenavCollapse
                  name={subItem.name}
                  icon={subItem.icon}
                  active={subItem.key === collapseName}
                  enableDropdown={openDropdowns[`${parentKey}/${subItem.key}`] ? true : false}
                  showDropdownIcon={subItem.subchildren?.length > 0}
                  showIcons={true}
                />
              </NavLink>

              {/* Render subchildren recursively if they exist */}
              {subItem.subchildren && openDropdowns[`${parentKey}/${subItem.key}`] && (
                <div style={{ paddingLeft: '20px' }}>
                  {renderSubChildren(subItem.subchildren, `${parentKey}/${subItem.key}`)}
                </div>
              )}
            </div>
          ))}
        </div>
      );
    };

    if (type === "collapse") {
      returnValue = (
        <div key={key}>
          {href ? (
            <Link
              href={href}
              target="_blank"
              rel="noreferrer"
              sx={{ textDecoration: "none" }}
            >
              <SidenavCollapse
                name={name}
                icon={icon}
                active={key === collapseName}
                noCollapse={noCollapse}
                onClick={() => handleDropdownClick(key)}
                enableDropdown={openDropdowns[key] ? true : false}
                showDropdownIcon={children?.length > 0}
                showIcons={true}
              />
            </Link>
          ) : (
            <NavLink to={route} onClick={() => handleDropdownClick(key)}>
              <SidenavCollapse
                name={name}
                icon={icon}
                active={key === collapseName}
                enableDropdown={openDropdowns[key] ? true : false}
                showDropdownIcon={children?.length > 0}
                showIcons={true}
              />
            </NavLink>
          )}

          {/* Render submenus if they exist */}
          {children && openDropdowns[key] && (
            <div style={{ paddingLeft: '0px' }}>
              {children.map((subItem) => (
                <div key={subItem.key}>
                  <NavLink to={subItem.route} onClick={() => handleDropdownClick(subItem.key, key)}>
                    <SidenavCollapse
                      name={subItem.name}
                      icon={subItem.icon}
                      active={subItem.key === collapseName}
                      enableDropdown={openDropdowns[`${key}/${subItem.key}`] ? true : false}
                      showDropdownIcon={subItem.subchildren?.length > 0}
                      showIcons={false}
                    />
                  </NavLink>

                  {/* Render subchildren if they exist */}
                  {subItem.subchildren && openDropdowns[`${key}/${subItem.key}`] && (
                    <div style={{ paddingLeft: '20px' }}>
                      {renderSubChildren(subItem.subchildren, `${key}/${subItem.key}`)}
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      );
    } else if (type === "title") {
      returnValue = (
        <MDTypography
          key={key}
          color={textColor}
          display="block"
          variant="caption"
          fontWeight="bold"
          textTransform="uppercase"
          pl={3}
          mt={2}
          mb={1}
          ml={1}
        >
          {title}
        </MDTypography>
      );
    } else if (type === "divider") {
      returnValue = (
        <Divider
          key={key}
          light={
            (!darkMode && !whiteSidenav && !transparentSidenav) ||
            (darkMode && !transparentSidenav && whiteSidenav)
          }
        />
      );
    }

    return returnValue;
  });


  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <MDBox pt={3} pb={1} px={4} textAlign="center">
        <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <MDTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}><CloseIcon /></Icon>
          </MDTypography>
        </MDBox>
        <MDBox component={NavLink} to="/" display="flex" alignItems="center">
          <MDBox component="img" src={"./vite.svg"} alt="Brand" width="2rem" />
          <MDBox
            width={!brandName && "100%"}
            sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
          >
            <MDTypography component="h6" variant="button" fontWeight="medium" color={textColor}>
              {brandName}
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      />
      <List>{renderRoutes}</List>

    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
