import React from 'react';

function Spinner() {
  return (
    <div className="spinner-container">
      <div className="sound_wave">
        <span />
        <span />
        <span />
        <span />
        <span />
        <span />
      </div>
    </div>
  );
}

export default Spinner;