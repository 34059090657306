import { TableCell, TableSortLabel } from "@mui/material";

export const TableHeadElement = (props) => {
    return (
        <>
            {props.setHead &&
                props.setHead.map((column) => (
                    <TableCell
                        key={column.id}
                        style={column?.style}
                        onClick={(event) => column.sort && props.sortFn(event, column.id)}
                    >
                        {column.sort && (
                            <TableSortLabel
                                active={props.orderBy === (column.id)}
                                direction={props.order}
                            >
                                {column.label}
                            </TableSortLabel>
                        )}
                        {!column.sort && column.label}
                    </TableCell >
                ))}
        </>
    );
};
