import { createApi } from "@reduxjs/toolkit/query/react";

import { axiosBaseQuery } from "./AxiosBaseQuery";

export const MicroServiceApi = createApi({
    reducerPath: "microServiceApi",
    baseQuery: axiosBaseQuery(),
    tagTypes: ["microServiceTag"],

    endpoints: (builder) => ({
        getMicroService: builder.query({
            query: (pageData) => ({
                url: `/admin/microservice/list`,
                method: "GET",
                params: {
                    limit: pageData.per_page ?? Number(process.env.REACT_APP_PER_PAGE),
                    page: pageData.page ?? 1,
                    order: pageData.order ?? process.env.REACT_APP_ORDER,
                    order_by: pageData.order_by ?? process.env.REACT_APP_ORDER_BY,
                    search: pageData.searchText,
                },
            }),
            providesTags: ["microServiceTag"],
        }),
        deployMicroService: builder.mutation({
            query: (data) => ({
                url: `/admin/microservice/runstack/${data?.microservice_name}`,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["microServiceTag"],
        }),
        getMicroServiceById: builder.query({
            query: (id) => ({
                url: `/admin/microservice/info/${id}`,
                method: "GET",
            }),
            providesTags: ["microServiceTag"],
        }),

        addMicroService: builder.mutation({
            query: (data) => ({
                url: `/admin/microservice/add`,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["microServiceTag"],
        }),
        editMicroService: builder.mutation({
            query: (data) => ({
                url: `/admin/microservice/edit/${data.id}`,
                method: "PUT",
                body: data,
            }),
            invalidatesTags: ["microServiceTag"],
        }),
        deleteMicroService: builder.mutation({
            query: (id) => ({
                url: `/admin/microservice/delete/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["microServiceTag"],
        }),
    })
});

export const {
    useGetMicroServiceQuery,
    useDeployMicroServiceMutation,
    useAddMicroServiceMutation,
    useEditMicroServiceMutation,
    useGetMicroServiceByIdQuery,
    useDeleteMicroServiceMutation,
} = MicroServiceApi;
