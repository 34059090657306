
import { Search } from "@mui/icons-material";
import { Grid, TextField } from "@mui/material";

export const SearchElement = (props) => {
    return (
        <Grid container className="search">
            <TextField
                id="search"
                type="text"
                placeholder={props.searchTag}
                name="name"
                autoComplete="off"
                onChange={props.searchFn}
            />
            <div className="search-icon">
                <Search />
            </div>
        </Grid>
    );
};
