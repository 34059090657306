import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLogin: false,
    role: null,
    user: null,
    permissions: [],
};

const AuthSlices = createSlice({
    name: "authSlices",
    initialState,
    reducers: {
        setAuth: (state, { payload }) => {
            state.isLogin = true;
            state.role = payload
        },
        setUserDetail: (state, { payload }) => {
            state.user = payload
        },
        setAuthLogout: (state) => {
            state.isLogin = false;
            state.role = null;
            localStorage.clear();
        },
        setUserPermissions: (state, { payload }) => {
            state.permissions = payload
        },
    },
});

const { reducer, actions } = AuthSlices;

export const { setAuth, setAuthLogout, setUserDetail, setUserPermissions } = actions;

export default reducer;
