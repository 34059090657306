import { Box, Button, Card, CardContent, CardHeader, Divider, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useAddMicroServiceMutation, useEditMicroServiceMutation, useGetMicroServiceByIdQuery } from '../../redux/apis/MicroServiceApi';

const AddEditService = ({ id, setOpen, setId }) => {

    const initialState = {
        microservice_name: "",
        service_status: 1,
    }
    const [formData, setFormData] = useState(initialState);

    const { data: MicroServiceDetail } = useGetMicroServiceByIdQuery(id, {
        skip: !id,
    });
    const [addMicroService, result] = useAddMicroServiceMutation();
    const [editMicroService, editResult] = useEditMicroServiceMutation();

    useEffect(() => {
        if (MicroServiceDetail?.success) {
            setFormData({ microservice_name: MicroServiceDetail?.data?.microservice_name, service_status: MicroServiceDetail?.data?.service_status });
        }
    }, [MicroServiceDetail]);

    useEffect(() => {
        if (result?.isSuccess && result?.data?.success) {
            setFormData({
                microservice_name: "",
                service_status: 1,
            });
            setOpen(false);
            setId("");
        }
    }, [result, setOpen, setId]);

    useEffect(() => {
        if (editResult?.isSuccess && editResult?.data?.success) {
            setFormData({
                microservice_name: "",
                service_status: 1,
            });
            setOpen(false);
            setId("");
        }
    }, [editResult, setOpen, setId]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        id ? editMicroService({ ...formData, id: id }) : addMicroService(formData)
    };

    return (
        <Paper style={{ borderRadius: "12px" }}>
            <Card className="main-container" elevation={3}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <CardHeader title={id ? "Edit MicroService" : "Add MicroService"} />
                    <HighlightOffIcon className="closeicon" onClick={() => { setOpen(false); }} />
                </Box>
                <Divider sx={{ border: "0.1px solid black", margin: "0px 15px" }} />
                <CardContent>
                    <Box component="form" sx={{ width: '70%', padding: 2 }} onSubmit={handleSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={3}>
                                <Typography style={{ fontSize: "medium", margin: "10px 0px" }}>Host Name:</Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <TextField
                                    fullWidth
                                    id="microservice_name"
                                    required
                                    disabled={id ? true : false}
                                    label="Enter host name"
                                    name="microservice_name"
                                    type="text"
                                    size="medium"
                                    value={formData.microservice_name}
                                    onChange={handleChange}
                                    variant="outlined"
                                />
                            </Grid>

                            <Grid item xs={3}>
                                <Typography style={{ fontSize: "medium", margin: "10px 0px" }}>Status:</Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <Box sx={{ minWidth: 120 }}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Select Status *</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            variant="outlined"
                                            sx={{
                                                paddingTop: '12px',
                                                paddingBottom: '12px',
                                            }}
                                            label="Select Status"
                                            name="service_status"
                                            required
                                            value={formData.service_status}
                                            onChange={handleChange}
                                        >
                                            <MenuItem value={1}>True</MenuItem>
                                            <MenuItem value={0}>False</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>
                            <Grid item xs={12} style={{ display: "flex" }}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    className="save-btn"
                                    style={{ marginRight: "18px" }}
                                    onSubmit={handleSubmit}
                                >
                                    Submit
                                </Button>
                                <Button
                                    type="button"
                                    variant="contained"
                                    className="close-btn"
                                    onClick={() => { setOpen(false); }}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </CardContent>
            </Card>
        </Paper>
    )
}

export default AddEditService